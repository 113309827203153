var $ = require("jquery")
require("slick-carousel")

document.addEventListener("DOMContentLoaded", function () {
  $('.scroller-route').slick({
    slidesToShow: 3,
    nextArrow: '.next-route',
    prevArrow: '.previous-route',
    customPaging: () => {
      return '<button class="featured-blog__pagination"></button>'
    },
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  })
})

