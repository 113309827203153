var $ = require("jquery")
require("slick-carousel")

document.addEventListener("DOMContentLoaded", function () {
  $('.scroller').slick({
    variableWidth: true,
    adaptiveHeight: true,
    nextArrow: '.next',
    prevArrow: '.previous',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          variableWidth: false
        }
      }
    ]
  })

  $('.review-item__content__review__read-more').each(function () {
    $(this).on('click', function () {
      $(this).next().addClass('open')
    })
  })

  $('.review-item__content__review__modal').each(function (_, obj) {
    $(this)
    .find('.review-item__content__review__modal-placeholder')
    .on('click', function () {
      $(obj).removeClass('open')
    })

    $(this)
    .find('.review-item__content__review__modal-close')
    .on('click', function () {
      $(obj).removeClass('open')
    })
  })
})

document.addEventListener("DOMContentLoaded", function () {
  $('.slider-syncing__for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    asNavFor: '.slider-syncing__nav'
  });
  $('.slider-syncing__nav').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
    asNavFor: '.slider-syncing__for',
    dots: false,
    arrows: false,
    centerMode: false,
    focusOnSelect: true
  });
})
