document.addEventListener("DOMContentLoaded", () => {

  // Modal Setup
  const modal = document.getElementById('modal');

  modal.addEventListener('click', () => {
    modal.style.display = "none";
  });

  const modalImg = document.getElementById("modal-content");

  $('.gallery__item').on("click", function (event) {
    const source = $(this).find('img').attr('src');
    modal.style.display = "flex";
    modalImg.src = source;
  });

})
