const getValue = target => {
  return target.siblings('.number-input__value').val()
}

const setValue = (target, value) => {
  const labelEl = target.siblings('.number-input__label')

  labelEl.html(`${labelEl.attr("label")} ${value}`)
  target.siblings('.number-input__value').val(value).trigger("change")
}

const initNumberInput = () => {
  $(".number-input__add").off("click").on("click", e => {
    const target = $(e.target)
    const value = Number(getValue(target))
    const newValue = value + 1

    const is_number_of_adults_input = target.parent().find("input[name=number_of_adults]").length
    const is_number_of_children_input = target.parent().find("input[name=number_of_children]").length

    const max = Number(target.parent().attr('max'))
    let maxValue = max || 100
    if (is_number_of_adults_input) {
      const number_of_children = Number($("input[name=number_of_children]").val())
      maxValue = (max - number_of_children) || 0
    } else if (is_number_of_children_input) {
      const number_of_adults = Number($("input[name=number_of_adults]").val())
      maxValue = (max - number_of_adults) || 0
    }

    setValue(target, newValue > maxValue ? maxValue : newValue)

    if (newValue > maxValue && (is_number_of_adults_input || is_number_of_children_input)) {
      target.parents().find(".extra-guests").removeClass("d-none")
    }
  })

  $(".number-input__subtract").off("click").on("click", e => {
    const target = $(e.target)
    const value = Number(getValue(target))
    const newValue = value - 1
    const minValue = Number(target.parent().attr('min')) || 0

    setValue(target, newValue < minValue ? minValue : newValue)

    const number_of_adults = target.parent().find("input[name=number_of_adults]")
    const number_of_children = target.parent().find("input[name=number_of_children]")
    if (number_of_adults || number_of_children) {
      target.parents().find(".extra-guests").addClass("d-none")
      target.parents().find("input[name='request_extra_guests']").prop('checked', false)
    }
  })
}

export { initNumberInput }
