var $ = require("jquery")

document.addEventListener("DOMContentLoaded", function () {
  $("#filter-categories").next(".dropdown-menu").children(".dropdown-item").on("click", function(e) {
    params = $.param({ category: $(this).text(), date: $("#filter-dates").text().trim() })
    url = window.location.origin + window.location.pathname
    window.location.href = url + "?" + params
  })

  $("#filter-dates").next(".dropdown-menu").children(".dropdown-item").on("click", function(e) {
    params = $.param({ category: $("#filter-categories").text().trim(), date: $(this).text() })
    url = window.location.origin + window.location.pathname
    window.location.href = url + "?" + params
  })
})
