import AirDatepicker from "air-datepicker"
import localeEn from "air-datepicker/locale/en"
import { initNumberInput } from "./number-input"
import { initPhoneInput } from "./phone-input"
import moment from "moment"
import editorjsHTML from "editorjs-html"

document.addEventListener("DOMContentLoaded", function () {
  const parseDescription = () => {
    const description = ".cruiser-detail__main__box > .description"
    const descriptionInput = ".cruiser-detail__main__box > .description-input"

    if ($(descriptionInput).length && $(descriptionInput).val()) {
      console.log($(descriptionInput).val())
      const content = JSON.parse($(descriptionInput).val())

      $(description).html(editorjsHTML().parse(content)).css("text-align", "justify")
    }
  }

  const sailingExperienceListener = () => {
    const checkboxs = document.querySelectorAll('.checkbox.sailing-experience input')

    if (!checkboxs.length) return

    checkboxs.forEach(checkbox => {
      checkbox.addEventListener('change', (e) => {
        if (e.target.checked) return checkbox.value = 'true'

        checkbox.value = 'false'
      })
    })
  }

  const newsLetterListener = () => {
    const checkboxs = document.querySelectorAll('.checkbox.news-letter input')

    if (!checkboxs.length) return

    checkboxs.forEach(checkbox => {
      checkbox.addEventListener('change', (e) => {
        if (e.target.checked) return checkbox.value = 'true'

        checkbox.value = 'false'
      })
    })
  }

  const createBooking = form => {
    showLoading(form)

    $.ajax({
      type: "post",
      url: $(form).attr("action"),
      data: $(form).serialize(),
      success: function (_, __, xhr) {
        if (xhr.status === 201) return location.href = "/reserveren/success"
        initBooking()
      },
      error: function (xhr) {
        if (xhr.status === 400) location.href = "/reserveren/failed"
      },
      complete: function(xhr) {
        hideLoading(form, xhr)
      }
    })
  }

  const editBooking = form => {
    showLoading(form)

    $.ajax({
      type: "post",
      url: "edit",
      data: $(form).serialize(),
      success: function () {
        initBooking()
      },
      complete: function(xhr) {
        hideLoading(form, xhr)
      }
    })
  }

  const updatePrice = () => {
    $.ajax({
      type: "post",
      url: "price",
      data: $(".booking-summary__form").serialize(),
      success: function () {
        initBooking()
      }
    })
  }

  const showLoading = form => {
    $(form).find('.button').addClass('running')
  }

  const hideLoading = (form, xhr) => {
    if ([201, 400].includes(xhr.status)) return

    $(form).find('.button').removeClass('running')

    if ($('.input--error').length) {
      const scrollTop = $('.input--error').eq(0).offset().top - 120
      $('html, body').animate({ scrollTop }, 50)
    } else {
      $('html, body').animate({ scrollTop: 0 }, 50)
    }
  }

  const initBooking = () => {
    initNumberInput()
    initPhoneInput()
    sailingExperienceListener()
    newsLetterListener()

    new AirDatepicker('.input--dob', {
      locale: localeEn,
      dateFormat: "yyyy-MM-dd",
      maxDate: new Date(),
      autoClose: true
    })

    $("input[name='time_zone']").val(moment().format('Z'))

    $(".price-summary__button").off("click").on("click", e => {
      $(e.target).parents('.price-summary').toggleClass("price-summary--expanded")
      $('html').toggleClass("overflow-visible")
      $('body').toggleClass("overflow-hidden")
    })

    $(".booking-summary__button-continue").off("click").on("click", e => {
      createBooking($(".booking-summary__form"))
    })

    $(".booking-summary__button-back").off("click").on("click", e => {
      editBooking($(".booking-summary__form"))
    })

    $(".extra, .extra .number-input input[type='hidden']").off("change").on("change", e => {
      updatePrice()
    })
  }

  parseDescription()
  initBooking()
})
