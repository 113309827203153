import intlTelInput from "intl-tel-input"
import utils from "intl-tel-input/build/js/utils"

export const initPhoneInput = () => {
  const inputWrappers = document.querySelectorAll(".phone-input")

  inputWrappers.forEach((wrapper, index) => {
    const input = wrapper.querySelector(".input__form")
    const hiddenValue = wrapper.querySelector(".input__hidden-value")
    const hiddenPhoneCode = wrapper.querySelector(".input__hidden-phone-code")
    const hiddenPhoneWithoutCode = wrapper.querySelector(".input__hidden-phone-without-code")

    const iti = intlTelInput(input, {
      utilsScript: utils,
      initialCountry: 'nl',
      onlyCountries: ['nl', 'be', 'de', 'fr'],
      separateDialCode: true
    })

    if (hiddenValue.value) {
      input.value = hiddenPhoneWithoutCode.value
      iti.setCountry(hiddenPhoneCode.value)
    }

    input.addEventListener('change', (e) => {
      hiddenValue.value = iti.getNumber()
      hiddenPhoneCode.value = iti.getSelectedCountryData().iso2
      hiddenPhoneWithoutCode.value = e.target.value
    })
  })
}
