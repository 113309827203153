document.addEventListener("DOMContentLoaded", function () {
  if (!$(".route-map__map").length) return

  const icon = "https://bootverhuurplas.ams3.cdn.digitaloceanspaces.com/static/routes-marker.png"
  const markers = []
  const infoWindows = []
  const routes = JSON.parse($(".route-map__map").attr("routes"))
  const content = document.querySelectorAll('.route-map__info-window')
  const buttons = document.querySelectorAll('.route-map__info-window__button')
  const legends = document.querySelectorAll('.route-map__legends-wrapper')
  const harbors = document.querySelectorAll('.route-itinerary__item')
  const header = document.querySelector('.header')

  buttons.forEach((button, index) => {
    button.addEventListener('click', (e) => {
      e.preventDefault()

      const scrollTop = harbors[index].offsetTop - header.clientHeight - 40
      $('html, body').animate({ scrollTop }, 300)
    })
  })

  routes.forEach((route, index) => {
    const markerPosition = { lat: route.latitude, lng: route.longitude }

    infoWindows.push({
      position: markerPosition,
      content: content[index]
    })

    markers.push({
      position: markerPosition,
      icon: icon,
      label: {
        text: `${index + 1}`,
        color: '#ffffff',
        fontSize: '16px',
        className: 'route-map__marker-label'
      }
    })
  })

  $(".route-map__map")
    .gmap3({
      center: markers[0].position,
      zoom: 10,
      mapId: '4c05e634b8adc345',
      mapTypeControl: false,
      zoomControl: false,
      streetViewControl: false
    })
    .marker(markers)
    .infowindow(infoWindows)
    .then(function (infowindow) {
      const map = this.get(0)
      const marker = this.get(1)

      if (!infowindow.length) return

      infowindow[0].open(map, marker[0])
      legends[0].classList.remove('d-none')

      marker.forEach((item, index) => {
        item.addListener('click', function() {
          infowindow.forEach((_, windowIndex) => infowindow[windowIndex].close())
          infowindow[index].open(map, item)
          legends.forEach(legend => legend.classList.add('d-none'))
          legends[index].classList.remove('d-none')
        })

        map.addListener('click', function() {
          infowindow.forEach((_, windowIndex) => infowindow[windowIndex].close())
          legends.forEach(legend => legend.classList.add('d-none'))
        })
      })
    })
})
