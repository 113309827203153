var $ = require("jquery")
require("slick-carousel")

document.addEventListener("DOMContentLoaded", function () {
  $('.featured-blog__scroller').slick({
    slidesToShow: 3,
    dots: false,
    infinite: false,
    customPaging: () => {
      return '<button class="featured-blog__pagination"></button>'
    },
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          dots: true
        }
      }
    ]
  })
})
