document.addEventListener("DOMContentLoaded", () => {
  const editorjsHTML = require("editorjs-html")

  $('.faq__navigator__item').off("click").on("click", function (e) {
    e.preventDefault()

    const faqID = $(e.target).attr("data-action")
    const scrollTop = $(`#${faqID}`).offset().top - 180
    $('html, body').animate({ scrollTop }, 100)
  })

  $('.faq__mobile-navigator .dropdown-item').off("click").on("click", function (e) {
    const faqID = $(e.target).attr("data-action")
    const scrollTop = $(`#${faqID}`).offset().top - 180

    $('.faq__mobile-navigator button').text($(e.target).text())
    $('html, body').animate({ scrollTop }, 100)
  })

  $(window).on("scroll", () => {
    if (!$(".faq").length) return

    const { scrollY } = window
    const { top } = $(".faq").offset()
    const { top: bottom } = $(".see-our-cruisers").offset()

    if (scrollY >= top - 150 && scrollY <= bottom - 500) {
      $('.faq__navigator').addClass('sticky')
      return
    }

    $('.faq__navigator').removeClass('sticky')
  })

  let tresholds = []
  const getTresholds = () => {
    const faqItems = $('.faq__item')
    tresholds = [...faqItems].map(item => (
      $(item).find('.accordion').offset().top
    ))
  }

  getTresholds()
  $('.card__header').on("click", function () {
    if (window.innerWidth < 992) return

    getTresholds()
  })

  $(window).on("scroll", () => {
    const { scrollY, innerWidth } = window

    if (innerWidth < 992) return

    const index = tresholds.findIndex(i => i >= scrollY)

    $('.faq__navigator__item.active').removeClass('active')
    $('.faq__navigator__item').eq(index).addClass('active')
  })

  $(".faq__item__answer").each(function (index, object) {
    const answer = JSON.parse($(this).find($("input[type='hidden']")).val())

    $(this).html(editorjsHTML().parse(answer))
  })
})
