var $ = require("jquery")
require("slick-carousel")

document.addEventListener("DOMContentLoaded", function () {
  $('.scroller-blog').slick({
    slidesToShow: 2,
    dots: false,
    nextArrow: '.next-blog',
    prevArrow: '.previous-blog',
    customPaging: () => {
      return '<button class="featured-blog__pagination"></button>'
    },
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          dots: true
        }
      }
    ]
  })
})

document.addEventListener("DOMContentLoaded", function () {
  $('.related-blog').slick({
    slidesToShow: 3,
    dots: false,
    nextArrow: '.next-blog',
    prevArrow: '.previous-blog',
    customPaging: () => {
      return '<button class="featured-blog__pagination"></button>'
    },
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          dots: false
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          dots: true
        }
      }
    ]
  })
})
