import moment from "moment"
import Litepicker from 'litepicker'
import 'litepicker/dist/plugins/mobilefriendly'
import Swal from 'sweetalert2'

document.addEventListener("DOMContentLoaded", function () {
  const pickerEl = document.getElementById('litepicker')

  if (!pickerEl) return

  const checkInLabel = $('.trip-date-picker__content__in span')
  const checkOutLabel = $('.trip-date-picker__content__out span')
  const startDateInput = $('.trip-date-picker__start-date')
  const endDateInput = $('.trip-date-picker__end-date')

  const start_date = startDateInput.val()
  const end_date = endDateInput.val()

  const start_season = $(pickerEl).attr("start_season")

  const end_season = $(pickerEl).attr("end_season")

  const seasons = JSON.parse($(pickerEl).attr("seasons"))

  const start_season_date = new Date(start_season)
  const current_date = new Date()
  const minDate = start_season_date <= current_date ? current_date.toISOString() : start_season_date.toISOString()

  const noMidweekSeasons = seasons.reduce((acc, season) => {
    if (!season.midweek) {
      acc.push([season.start_date, season.end_date])
    }

    return acc
  }, [])

  new Litepicker({
    element: pickerEl,
    plugins: ['mobilefriendly'],
    singleMode: false,
    minDate,
    maxDate: new Date(end_season).toISOString(),
    startDate: new Date(start_date),
    endDate: new Date(end_date),
    firstDay: 0,
    minDays: 3,
    lang: "nl-NL",
    tooltipText: {
      one: 'dag',
      other: 'dagen'
    },

    tooltipNumber: (totalDays) => {
      return totalDays - 1
    },
    lockDaysFilter: (date) => {
      const day = date.getDay()

      const noMidweekSeason = noMidweekSeasons.length && noMidweekSeasons.every(season => (
        moment(date.dateInstance)
          .isBetween(moment(season[0]).startOf('day'), moment(season[1]).endOf('day'))
      ))

      if (noMidweekSeason) return ![5].includes(day)

      return ![1, 5].includes(day)
    },
    setup: (picker) => {
      picker.on('selected', (date1, date2) => {
        const date1Instance = moment(date1.dateInstance)
        const date2Instance = moment(date2.dateInstance)

        const startDay = date1Instance.day()
        const diffInDays = date2Instance.diff(date1Instance, "days")
        const diffInWeeks = date2Instance.diff(date1Instance, "weeks")
        const season = seasons.filter(({ start_date, end_date }) => (
          moment(startDay)
            .isBetween(moment(start_date).startOf('day'), moment(end_date).endOf('day'))
        ))

        const weekend = season[0]?.weekend
        const midweek = season[0]?.midweek
        const weekly = season[0]?.week
        const maximum_rent_period = season[0]?.maximum_rent_period || 0

        if (weekend && !midweek && !weekly && diffInDays > 3) {
          Swal.fire({
            icon: "warning",
            text: I18n.t('partials.trip_planner.only_weekend_booking')
          })
          picker.clearSelection()
          return
        }

        if (!weekend && midweek && !weekly && (startDay !== 1 || diffInDays > 4)) {
          Swal.fire({
            icon: "warning",
            text: I18n.t('partials.trip_planner.only_midweek_booking')
          })
          picker.clearSelection()
          return
        }

        if (!weekend && !midweek && weekly && (startDay !== 5 || diffInDays < 7)) {
          Swal.fire({
            icon: "warning",
            text: I18n.t('partials.trip_planner.only_weekly_booking')
          })
          picker.clearSelection()
          return
        }

        if (!weekend && midweek && weekly) {
          if (
            diffInDays < 4 ||
            (
              diffInDays % 4 !== 0 &&
              diffInDays % 7 !== 0 &&
              diffInDays % 11 !== 0
            )
          ) {
            Swal.fire({
              icon: "warning",
              text: I18n.t('partials.trip_planner.only_midweek_and_weekly_booking')
            })
            picker.clearSelection()
            return
          }
        }

        if (weekend && !midweek && weekly) {
          if (
            startDay === 1 ||
            (
              diffInDays % 3 !== 0 &&
              diffInDays % 7 !== 0 &&
              diffInDays % 10 !== 0
            )
          ) {
            Swal.fire({
              icon: "warning",
              text: I18n.t('partials.trip_planner.only_weekend_and_weekly_booking')
            })
            picker.clearSelection()
            return
          }
        }

        if (maximum_rent_period !== 0 && diffInWeeks > maximum_rent_period) {
          toastr.warning(I18n.t('partials.trip_planner.max_week', { max_week: maximum_rent_period }), null, toastrOptions)
          picker.clearSelection()
          return
        }

        const formattedDate1 = date1Instance.format("ddd, DD MMM yyyy")
        const formattedDate2 = date2Instance.format("ddd, DD MMM yyyy")

        checkInLabel.html(formattedDate1 || checkInLabel.attr('label'))
        checkOutLabel.html(formattedDate2 || checkOutLabel.attr('label'))

        startDateInput.val(date1Instance.format("YYYY-MM-DD") || '')
        endDateInput.val(date2Instance.format("YYYY-MM-DD") || '')

        const submitButton = document.getElementsByClassName('trip-planner__submit-button')[0]

        if (submitButton.getAttribute('data-autosubmit') === 'true') {
          submitButton.click()
        }

      }),
        picker.on('clear:selection', () => {
          checkInLabel.html(checkInLabel.attr('label'))
          checkOutLabel.html(checkOutLabel.attr('label'))
          startDateInput.val('')
          endDateInput.val('')
        })
    },
  })
})
