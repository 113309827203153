import editorjsHTML from "editorjs-html"

var $ = require("jquery")

document.addEventListener("DOMContentLoaded", function () {
  const imageParser = (block) => {
    let images = []

    switch (block.data.imageCount) {
      case 2:
        images.push(`<div class="blog__images">`)
        images.push(block.data.images.map((image, index) => {
          if (index % 2 == 0)
            return `<div class="blog__image blog__image--left"><img src="${image.url}"></div>`
          else
            return `<div class="blog__image blog__image--right"><img src="${image.url}"></div>`
        }))
        images.push(`</div>`)
        break;

      default:
        images = block.data.images.map((image) => `<div class="blog__images blog__images--single"><div class="blog__image"><img src="${image.url}"></div></div>`)
        break;
    }

    return images.join('')
  }

  const titleWithContentParser = (block) => {
    return `<h3>${block.data.title}</h3><p>${block.data.content}</p>`
  }

  $(".blog > .container").each(function (index, object) {
    const content = JSON.parse($(this).find($("input[type='hidden']")).val())

    $(this).html(editorjsHTML({ image: imageParser, title_with_content: titleWithContentParser }).parse(content))
  })
})
