document.addEventListener("DOMContentLoaded", () => {
  window.scrollTo(0, 0)

  $('.button-back-to-top').on("click", function (e) {
    e.preventDefault()

    const scrollTop = 0
    $('html, body').animate({ scrollTop }, 300)
  })
})

document.addEventListener("DOMContentLoaded", () => {
  $('.button-back-to-booking').on("click", function (e) {
    e.preventDefault()

    $('html, body').animate({
      scrollTop: $('.trip-planner').offset().top - 150
    }, 300)
  })
})
