import Swal from 'sweetalert2'
import "gmap3"

document.addEventListener("DOMContentLoaded", function () {
  const contactForm = $('.contact-us-form')

  var submitInvisibleRecaptchaForm = () => {
    $.ajax({
      type: "post",
      url: contactForm.attr("action"),
      data: contactForm.serialize(),
      success: function() {
        $('.contact-us-form input,textarea').val('')
        Swal.fire({
          icon: "success",
          text: I18n.t('contact.index.form.success_message')
        })
      },
      error: function(data) {
        const errors = JSON.parse(data.responseText)
        Swal.fire({
          icon: "error",
          text: I18n.t('contact.index.form.error_message')
        })

        $.each(errors, (key, error) => {
          const inputParent = $(`[name="message[${key}]"]`).parent()
          inputParent.addClass('input--error')
          inputParent.append(`
            <span class="input__error">${key} ${error[0]}</span>
          `)
        })
      },
      complete: function() {
        contactForm.find('.button').removeClass('running')
      }
    })
  }

  window.submitInvisibleRecaptchaForm = submitInvisibleRecaptchaForm

  contactForm.on("submit", (e) => {
    e.preventDefault()
    $(e.target).find('.button').addClass('running')
    $('.input--error .input__error').remove()
    $('.input--error').removeClass('input--error')
    grecaptcha.execute()
  })

  $(".map").each((__, target) => {
    const center = $(target).attr("coordinate").split(',')
    $(target)
      .gmap3({
        center: center,
        zoom: 16,
        mapId: '4c05e634b8adc345',
        mapTypeControl: false,
        zoomControl: false,
        streetViewControl: false
      })
      .marker({
        position: center,
        icon: "https://bootverhuurplas.ams3.cdn.digitaloceanspaces.com/static/marker.png"
      })
  })
})

document.addEventListener("DOMContentLoaded", () => {
  const parkingButton = document.querySelector('.contact-parking-button')
  const parkings = document.getElementById('parkings')

  if (!parkings) return

  parkingButton.addEventListener("click", function () {
    parkings.scrollIntoView()
  })
})

document.addEventListener("DOMContentLoaded", () => {
  const getintouchButton = document.querySelector('.contact-getintouch-button')
  const getintouch = document.getElementById('getintouch')

  if (!getintouch) return

  getintouchButton.addEventListener("click", function () {
    getintouch.scrollIntoView()
  })
})
