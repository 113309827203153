import Swal from 'sweetalert2'

const booking = document.getElementsByClassName('booking-alert');

document.addEventListener("DOMContentLoaded", function () {
  if (booking.length) {
    Swal.fire({
      icon: "error",
      text: booking[0].textContent
    })
  }
})
