document.addEventListener("DOMContentLoaded", () => {
  // Shrink Header on Scroll
  const header = document.querySelector('.header')
  const button = document.querySelector('.button-back-to-top')
  const html = document.querySelector('html')
  const body = document.querySelector('body')

  const headerStylingHandler = () => {
    if (window.scrollY > 10) {
      header.classList.add('scrolled')
      button.classList.add('show')
    } else {
      header.classList.remove('scrolled')
      button.classList.remove('show')
    }

    if (html.classList.contains('overflow-hidden')) {
      html.classList.remove("overflow-hidden")
    }
  }

  headerStylingHandler()

  window.addEventListener('scroll', headerStylingHandler)

  // Responsive header
  const hamburger = document.querySelector(".hamburger")
  const navMenu = document.querySelector(".nav-menu")

  function mobileMenu() {
    hamburger.classList.toggle("active")
    navMenu.classList.toggle("active")
    header.classList.toggle("active")
    html.classList.toggle("overflow-hidden")
    body.classList.toggle("overflow-hidden")
  }

  hamburger.addEventListener("click", mobileMenu)

  window.addEventListener('resize', () => {
    hamburger.classList.remove("active")
    navMenu.classList.remove("active")
    header.classList.remove("active")
    html.classList.remove("overflow-hidden")
    body.classList.remove("overflow-hidden")
    headerStylingHandler()
  })
})
