// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require jquery3
//= require popper
//= require bootstrap-sprockets
import Rails from "@rails/ujs"

Rails.start()

import I18n from 'i18n-js'
window.I18n = I18n

import "popper.js"
import "bootstrap"

import "./header"
import "./back-to-top"
import "./partials/review-slider"
import "./partials/trip-planner"
import "./partials/booking-summary"
import "./partials/booking-alert"
import "./partials/faq"
import "./partials/featured-blog"
import "./partials/route-slider"
import "./partials/contact"
import "./partials/blog-slider"
import "./partials/route"
import "./partials/blog-filter"
import "./partials/image-viewer"
import "./partials/blog-parser"
import "./partials/phone-input"
